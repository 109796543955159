<template>
  <ui-component-modal
    modalTitle="Edit meeting type settings"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveMeetingtype"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td>Allow prices</td>
              <td class="has-text-right">
                <div class="tabs is-toggle is-small is-pulled-right">
                  <ul>
                    <li :class="{ 'is-active': mMeetingtype.AllowPrices }">
                      <a @click="mMeetingtype.AllowPrices = true">
                        <span>Yes</span>
                      </a>
                    </li>
                    <li :class="{ 'is-active': !mMeetingtype.AllowPrices }">
                      <a @click="mMeetingtype.AllowPrices = false">
                        <span>No</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>Capacity check</td>
              <td class="has-text-right">
                <div v-if="mMeetingtype.MeetingtypeId === 1">
                  <span class="icon">
                    <font-awesome-icon
                      v-if="meetingtype.CheckCapacity"
                      :icon="['fas', 'check']"
                      class="has-text-success"
                    />
                    <font-awesome-icon
                      v-if="!meetingtype.CheckCapacity"
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                    />
                  </span>
                </div>
                <div
                  v-if="mMeetingtype.MeetingtypeId !== 1"
                  class="tabs is-toggle is-small is-pulled-right"
                >
                  <ul>
                    <li :class="{ 'is-active': mMeetingtype.CheckCapacity }">
                      <a @click="mMeetingtype.CheckCapacity = true">
                        <span>Yes</span>
                      </a>
                    </li>
                    <li :class="{ 'is-active': !mMeetingtype.CheckCapacity }">
                      <a @click="mMeetingtype.CheckCapacity = false">
                        <span>No</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>Confirm first</td>
              <td class="has-text-right">
                <div class="tabs is-toggle is-small is-pulled-right">
                  <ul>
                    <li :class="{ 'is-active': mMeetingtype.MustBeConfirmed }">
                      <a @click="mMeetingtype.MustBeConfirmed = true">
                        <span>Yes</span>
                      </a>
                    </li>
                    <li :class="{ 'is-active': !mMeetingtype.MustBeConfirmed }">
                      <a @click="mMeetingtype.MustBeConfirmed = false">
                        <span>No</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>

            <tr>
              <td>Book max. days out</td>
              <td>
                <div class="tabs is-toggle is-small is-pulled-right">
                  <ul>
                    <li
                      :class="{
                        'is-active': mMeetingtype.MaxDaysOut === -1,
                      }"
                    >
                      <a @click="setMaxDaysOut(false)">
                        <span>{{ $t(`General.State_Off`) }}</span>
                      </a>
                    </li>
                    <li
                      :class="{
                        'is-active': mMeetingtype.MaxDaysOut > -1,
                      }"
                    >
                      <a @click="setMaxDaysOut(true)">
                        <span>{{ $t(`General.State_On`) }}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <span v-if="mMeetingtype.MaxDaysOut > -1">
                  <input
                    type="text"
                    v-model="mMeetingtype.MaxDaysOut"
                    class="input"
                  />
                  days
                </span>
              </td>
            </tr>

            <tr>
              <td>Minutes between reservations</td>
              <td>
                <div class="select is-pulled-right">
                  <select v-model="mMeetingtype.MinutesBetweenReservations">
                    <option
                      :value="0"
                      :selected="mMeetingtype.MinutesBetweenReservations === 0"
                    >
                      0
                    </option>
                    <option
                      v-if="mMeetingtype.MeetingtypeId == 2"
                      :value="15"
                      :selected="mMeetingtype.MinutesBetweenReservations === 15"
                    >
                      15
                    </option>
                    <option
                      :value="30"
                      :selected="mMeetingtype.MinutesBetweenReservations === 30"
                    >
                      30
                    </option>
                    <option
                      v-if="mMeetingtype.MeetingtypeId == 2"
                      :value="45"
                      :selected="mMeetingtype.MinutesBetweenReservations === 45"
                    >
                      45
                    </option>
                    <option
                      :value="60"
                      :selected="mMeetingtype.MinutesBetweenReservations === 60"
                    >
                      60
                    </option>
                  </select>
                </div>
              </td>
            </tr>

            <tr>
              <td>Days until start</td>
              <td>
                <table class="table is-fullwidth is-striped">
                  <tbody>
                    <tr v-for="(day, index) in mMeetingtype.Days" :key="index">
                      <td>{{ day.Day | getDayName }}</td>
                      <td>
                        <div class="select">
                          <select v-model="day.DaysToStart">
                            <option
                              :value="0"
                              :selected="day.DaysToStart === 0"
                            >
                              0
                            </option>
                            <option
                              :value="1"
                              :selected="day.DaysToStart === 1"
                            >
                              1
                            </option>
                            <option
                              :value="2"
                              :selected="day.DaysToStart === 2"
                            >
                              2
                            </option>
                            <option
                              :value="3"
                              :selected="day.DaysToStart === 3"
                            >
                              3
                            </option>
                            <option
                              :value="4"
                              :selected="day.DaysToStart === 4"
                            >
                              4
                            </option>
                            <option
                              :value="5"
                              :selected="day.DaysToStart === 5"
                            >
                              5
                            </option>
                            <option
                              :value="6"
                              :selected="day.DaysToStart === 6"
                            >
                              6
                            </option>
                            <option
                              :value="7"
                              :selected="day.DaysToStart === 7"
                            >
                              7
                            </option>
                          </select>
                        </div>
                      </td>
                      <td>
                        {{
                          calculateNextDay(day.Day, day.DaysToStart)
                            | getDayName
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'

export default {
  components: {},

  props: {
    meetingtype: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mMeetingtype: JSON.parse(JSON.stringify(this.meetingtype)),
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {},

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    calculateNextDay(day, daysToStart) {
      let nextDay = day + daysToStart

      if (nextDay > 7) {
        nextDay = nextDay - 7
      }

      return nextDay
    },

    setMaxDaysOut(active) {
      if (active) {
        this.mMeetingtype.MaxDaysOut = 90
      }
      if (!active) {
        this.mMeetingtype.MaxDaysOut = -1
      }
    },

    saveMeetingtype() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        locationProvider.methods
          .saveLocationMeetingtype(self.location.Id, self.mMeetingtype)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              let mLocation = JSON.parse(JSON.stringify(this.location))
              let meetingtypeIndex = mLocation.Meetingtypes.findIndex(
                (m) => m.MeetingtypeId === self.mMeetingtype.MeetingtypeId
              )
              if (meetingtypeIndex > -1) {
                Vue.set(
                  mLocation.Meetingtypes,
                  meetingtypeIndex,
                  self.mMeetingtype
                )
                self.setLocation(mLocation)
              }

              setTimeout(() => {
                self.onClickCancel()
              }, 1000)
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
